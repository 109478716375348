import { useState, useEffect } from "react";
import { motion, useViewportScroll, useTransform, AnimatePresence } from "framer-motion";
import ShimmerButton from "../components/magicui/shimmer-button";
import "../styles/loader.css";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Arrow } from "../components/arrow";
import { loadStripe } from "@stripe/stripe-js";
import { IoChevronForward, IoChevronBack } from "react-icons/io5";

const stripePromise = loadStripe(
  "pk_live_51Pb5rcHnQLCbZW4Yq03X1C19tZqlBX4uVm0DO5GQ18lpOjYKhWQeo04xLoiwP4Zv91YUJacL9F1LEtelAcdj0MRm008TM7yz7B"
);

//const stripePromise = loadStripe('pk_test_51M5XfmHtRheYJmNr5Jtn8vwuNv6vqd7C3YpZyw9dOCFF7OOa1bBib3P126COAbYy6LtV9UGjmlmNo8CCTEjFCwuv00cdOSA8lZ');

ReactGA.initialize("G-78TF44GTX6");
ReactGA.send({ hitType: "pageview", page: "window.location.pathname" });

interface FormData {
  [key: string]: string;  // Permet l'indexation dynamique avec des chaînes
}

// Ajout de l'interface pour les étapes
interface Step {
  id: number;
  title: string;
  fields: string[];
}

const Status = () => {
  const [formData, setFormData] = useState<FormData>({
    email: "",
    name: "",
    "Votre forme juridique": "",
    "CODE POSTALE SIEGE SOCIAL": "",
    "Nombre d'associé": "",
    "Votre objet social complet": "",
    "Votre dénomination sociale": "",
    "Date de début d'activité": "",
    "Date de clôture de l'exercice comptable": "",
    "ADRESSE SIEGE SOCIAL N° ET RUE": "",
    "VILLE SIEGE SOCIAL": "",
    "MONTANT DE CAPITAL": "",
    "Nombre de parts sociales ou actions": "",
    "Nom de LA BANQUE ou est / ou sera déposé le capital": "",
    "Date de clôture du premier exercice comptable": "",
    "Indiquez la civilité de l'associé 1": "",
    "Indiquez le nom de naissance de l'associé 1": "",
    "Indiquez le nom d'usage de l'associé 1": "",
    "Indiquez le prénom de l'associé 1": "",
    "Indiquez le deuxième prénom de l'associé 1": "",
    "Indiquez la date de naissance de l'associé 1": "",
    "Indiquez le lieu de naissance de l'associé 1": "",
    "Indiquez le département de naissance de l'associé 1": "",
    "Indiquez la nationalité de l'associé 1": "",
    "Indiquez l'adresse postale de l'associé 1 (N° de rue nom de rue)": "",
    "Indiquez l'adresse postale de l'associé 1 (VILLE)": "",
    "Indiquez l'adresse postale de l'associé 1 (CODE POSTAL)": "",
    "Indiquez le statut matrimonial de l'associé 1": "",
    "Indiquez le pourcentage de détention de l'associé 1": "",
    "Indiquez si l'associé 1 est également gérant": "",
    "Indiquez la civilité de l'associé 2": "",
    "Indiquez le nom de naissance de l'associé 2": "",
    "Indiquez le nom d'usage de l'associé 2": "",
    "Indiquez le prénom de l'associé 2": "",
    "Indiquez le deuxième prénom de l'associé 2": "",
    "Indiquez la date de naissance de l'associé 2": "",
    "Indiquez le lieu de naissance de l'associé 2": "",
    "Indiquez le département de naissance de l'associé 2": "",
    "Indiquez la nationalité de l'associé 2": "",
    "Indiquez l'adresse postale de l'associé 2 (N° de rue nom de rue)": "",
    "Indiquez l'adresse postale de l'associé 2 (VILLE)": "",
    "Indiquez l'adresse postale de l'associé 2 (CODE POSTAL)": "",
    "Indiquez le statut matrimonial de l'associé 2": "",
    "Indiquez le pourcentage de détention de l'associé 2": "",
    "Indiquez si l'associé 2 est également gérant": "",
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const imagePaths = [
    "/img/STATUTS a utiliser pour apercu-01.png",
    "/img/STATUTS a utiliser pour apercu-02.png",
    "/img/STATUTS a utiliser pour apercu-03.png",
    "/img/STATUTS a utiliser pour apercu-04.png",
    "/img/STATUTS a utiliser pour apercu-05.png",
    "/img/STATUTS a utiliser pour apercu-06.png",
    "/img/STATUTS a utiliser pour apercu-07.png",
    "/img/STATUTS a utiliser pour apercu-08.png",
    "/img/STATUTS a utiliser pour apercu-09.png",
    "/img/STATUTS a utiliser pour apercu-10.png",
    "/img/STATUTS a utiliser pour apercu-11.png",
    "/img/STATUTS a utiliser pour apercu-12.png",
    "/img/STATUTS a utiliser pour apercu-13.png",
    "/img/STATUTS a utiliser pour apercu-14.png",
    "/img/STATUTS a utiliser pour apercu-15.png",
    "/img/STATUTS a utiliser pour apercu-16.png",
    "/img/STATUTS a utiliser pour apercu-17.png",
    "/img/STATUTS a utiliser pour apercu-18.png",
    "/img/STATUTS a utiliser pour apercu-19.png",
    "/img/STATUTS a utiliser pour apercu-20.png",
    "/img/STATUTS a utiliser pour apercu-21.png",
    "/img/STATUTS a utiliser pour apercu-22.png",
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const { scrollYProgress } = useViewportScroll();
  const scaleX = useTransform(scrollYProgress, [0, 1], [0, 1]);

  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);

  // Définition des étapes
  const steps: Step[] = [
    {
      id: 1,
      title: "Informations préalables",
      fields: ["email", "Votre forme juridique", "Nombre d'associé"]
    },
    {
      id: 2,
      title: "Dénomination et activité",
      fields: [
        "Votre objet social complet",
        "Votre dénomination sociale",
        "Date de début d'activité",
        "Date de clôture de l'exercice comptable"
      ]
    },
    {
      id: 3,
      title: "Informations juridiques",
      fields: [
        "ADRESSE SIEGE SOCIAL N° ET RUE",
        "CODE POSTALE SIEGE SOCIAL",
        "VILLE SIEGE SOCIAL",
        "MONTANT DE CAPITAL",
        "Nombre de parts sociales ou actions",
        "Nom de LA BANQUE ou est / ou sera déposé le capital",
        "Date de clôture du premier exercice comptable"
      ]
    },
    {
      id: 4,
      title: "Informations des associés",
      fields: [] // Les champs des associés sont dynamiques
    },
    {
      id: 5,
      title: "Récapitulatif",
      fields: []
    }
  ];


  // Calcul du pourcentage de progression
  const progress = (currentStep / steps.length) * 100;

  // Fonction pour vérifier si une étape est complète
  const isStepComplete = (stepId: number) => {
    const step = steps.find(s => s.id === stepId);
    if (!step) {
      return false;
    }

    // Pour l'étape 1
    if (stepId === 1) {
      // Vérifier uniquement email et forme juridique
      return ["email", "Votre forme juridique"].every(field => {
        const value = formData[field];
        return value !== undefined && value !== "";
      });
    }

    // Pour l'étape 4 (associés)
    if (stepId === 4) {
      // Si "Aucun associé" est sélectionné ou le champ est vide, l'étape est considérée comme complète
      if (formData["Nombre d'associé"] === "Aucun associé" || !formData["Nombre d'associé"]) {
        return true;
      }

      // Sinon, vérifier les champs de l'associé courant
      const requiredFields = [
        `Indiquez la civilité de l'associé ${currentAssociate}`,
        `Indiquez le nom de naissance de l'associé ${currentAssociate}`,
        `Indiquez le nom d'usage de l'associé ${currentAssociate}`,
        `Indiquez le prénom de l'associé ${currentAssociate}`,
        `Indiquez le deuxième prénom de l'associé ${currentAssociate}`,
        `Indiquez la date de naissance de l'associé ${currentAssociate}`,
        `Indiquez le lieu de naissance de l'associé ${currentAssociate}`,
        `Indiquez le département de naissance de l'associé ${currentAssociate}`,
        `Indiquez la nationalité de l'associé ${currentAssociate}`,
        `Indiquez l'adresse postale de l'associé ${currentAssociate} (N° de rue nom de rue)`,
        `Indiquez l'adresse postale de l'associé ${currentAssociate} (VILLE)`,
        `Indiquez l'adresse postale de l'associé ${currentAssociate} (CODE POSTAL)`,
        `Indiquez le statut matrimonial de l'associé ${currentAssociate}`,
        `Indiquez le pourcentage de détention de l'associé ${currentAssociate}`,
        `Indiquez si l'associé ${currentAssociate} est également gérant`
      ];

      return requiredFields.every(field => {
        const value = formData[field];
        return value !== undefined && value !== "";
      });
    }

    // Pour les autres étapes
    return step.fields.every(field => {
      const value = formData[field];
      return value !== undefined && value !== "";
    });
  };

  // Ajoutez cette fonction pour obtenir les noms des champs manquants
  const getMissingFields = (stepId: number) => {
    const step = steps.find(s => s.id === stepId);
    if (!step) return [];

    // Pour l'étape 1, ne pas inclure le nombre d'associés dans la validation
    if (stepId === 1) {
      const fieldsToCheck = ["email", "Votre forme juridique"];
      return fieldsToCheck
        .filter(field => !formData[field])
        .map(field => {
          const fieldLabels: { [key: string]: string } = {
            "email": "Adresse email",
            "Votre forme juridique": "Forme juridique",
          };
          return fieldLabels[field] || field;
        });
    }

    // Pour l'étape 4 (associés)
    if (stepId === 4) {
      // Si aucun associé n'est sélectionné, retourner un tableau vide
      if (!formData["Nombre d'associé"] || formData["Nombre d'associé"] === "") {
        return [];
      }

      const missingFields: string[] = [];
      const currentAssociateNumber = currentAssociate;
      
      const associateFieldLabels: { [key: string]: string } = {
        [`Indiquez la civilité de l'associé ${currentAssociateNumber}`]: "Civilité",
        [`Indiquez le nom de naissance de l'associé ${currentAssociateNumber}`]: "Nom de naissance",
        [`Indiquez le nom d'usage de l'associé ${currentAssociateNumber}`]: "Nom d'usage",
        [`Indiquez le prénom de l'associé ${currentAssociateNumber}`]: "Prénom",
        [`Indiquez le deuxième prénom de l'associé ${currentAssociateNumber}`]: "Deuxième prénom",
        [`Indiquez la date de naissance de l'associé ${currentAssociateNumber}`]: "Date de naissance",
        [`Indiquez le statut matrimonial de l'associé ${currentAssociateNumber}`]: "Statut matrimonial",
        [`Indiquez le pourcentage de détention de l'associé ${currentAssociateNumber}`]: "Pourcentage de détention",
        [`Indiquez si l'associé ${currentAssociateNumber} est également gérant`]: "Statut de gérant"
      };

      Object.entries(associateFieldLabels).forEach(([field, label]) => {
        if (!formData[field] || formData[field] === "") {
          missingFields.push(`${label} de l'associé ${currentAssociateNumber}`);
        }
      });

      return missingFields;
    }

    // Pour les autres étapes
    return step.fields.filter(field => !formData[field]).map(field => {
      const fieldLabels: { [key: string]: string } = {
        "email": "Adresse email",
        "Votre forme juridique": "Forme juridique",
        "Nombre d'associé": "Nombre d'associés",
        "Votre objet social complet": "Objet social",
        "Votre dénomination sociale": "Dénomination sociale",
        "Date de début d'activité": "Date de début d'activité",
        "Date de clôture de l'exercice comptable": "Date de clôture",
        "ADRESSE SIEGE SOCIAL N° ET RUE": "Adresse du siège social",
        "CODE POSTALE SIEGE SOCIAL": "Code postal",
        "VILLE SIEGE SOCIAL": "Ville",
        "MONTANT DE CAPITAL": "Montant du capital",
        "Nombre de parts sociales ou actions": "Nombre de parts sociales",
        "Nom de LA BANQUE ou est / ou sera déposé le capital": "Nom de la banque",
        "Date de clôture du premier exercice comptable": "Date de clôture du premier exercice"
      };
      return fieldLabels[field] || field;
    });
  };

  // Fonction pour passer à l'étape suivante
  const nextStep = () => {
    if (currentStep < steps.length && isStepComplete(currentStep)) {
      // Si on est à l'étape 3 et qu'il n'y a pas d'associé ou "Aucun associé" est sélectionné, passer directement à la dernière étape
      let nextStepNumber = currentStep + 1;
      if (currentStep === 3 && (formData["Nombre d'associé"] === "Aucun associé" || !formData["Nombre d'associé"])) {
        nextStepNumber = steps.length;
      }

      const titleElement = document.querySelector('.text-3xl.mt-20');
      if (titleElement) {
        titleElement.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
      setTimeout(() => {
        setCompletedSteps(prev => [...prev, currentStep]);
        setCurrentStep(nextStepNumber);
        setErrorMessage("");
      }, 100);
    }
  };

  // Fonction pour revenir à l'étape précédente
  const previousStep = () => {
    if (currentStep > 1) {
      // Trouver l'élément de titre en haut du formulaire
      const titleElement = document.querySelector('.text-3xl.mt-20');
      
      // Si l'élément existe, faire défiler jusqu'à lui
      if (titleElement) {
        titleElement.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }

      // Mettre à jour l'état après le scroll
      setTimeout(() => {
        setCurrentStep(prev => prev - 1);
      }, 100);
    }
  };


  // Modifiez le renderStepIndicator pour gérer le format mobile
  const renderStepIndicator = () => (
    <div className="w-full max-w-4xl mx-auto mb-8 px-4">
      <div className={`${isMobile ? 'overflow-x-auto hide-scrollbar' : ''}`}>
        <div className={`flex ${isMobile ? 'w-max space-x-8 px-2' : 'justify-between'} mb-4`}>
          {steps
            .filter(step => {
              if (step.id === 4) {
                return formData["Nombre d'associé"] && formData["Nombre d'associé"] !== "";
              }
              return true;
            })
            .map((step, index) => {
              const isCompleted = completedSteps.includes(step.id);

              return (
                <div
                  key={step.id}
                  onClick={() => {
                    if (isCompleted || step.id === currentStep) {
                      setCurrentStep(step.id);
                    }
                  }}
                  className={`flex flex-col items-center flex-shrink-0 ${
                    currentStep >= step.id ? "text-blue-600" : "text-gray-400"
                  } ${(isCompleted || step.id === currentStep) ? "cursor-pointer group" : "cursor-not-allowed"}
                  relative p-2 min-w-[120px]`}
                >
                  <div
                    className={`w-10 h-10 rounded-full flex items-center justify-center mb-2 
                      ${currentStep >= step.id
                        ? "bg-blue-600 text-white"
                        : "bg-gray-200 text-gray-600"
                      } 
                      ${isCompleted ? "bg-blue-600" : ""}
                      ${(isCompleted || step.id === currentStep)
                        ? "transition-all duration-200 group-hover:bg-blue-700" 
                        : "opacity-60"
                      }
                      relative z-10`}
                  >
                    {isCompleted ? (
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                    ) : (
                      index + 1
                    )}
                  </div>
                  <span className={`text-sm text-center whitespace-nowrap transition-colors duration-200 px-2
                    ${(isCompleted || step.id === currentStep)
                      ? "group-hover:text-blue-700" 
                      : ""
                    }`}
                  >
                    {step.title}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
      <div className="relative h-1 bg-gray-200 mt-4">
        <motion.div
          className="absolute top-0 left-0 h-full bg-blue-600"
          initial={{ width: "0%" }}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 0.3 }}
        />
      </div>
    </div>
  );


  // Modification du rendu du formulaire pour afficher uniquement l'étape courante
  const renderCurrentStep = () => {
    const currentStepData = steps[currentStep - 1];
    
    return (
      <AnimatePresence mode="wait">
        <motion.div
          key={currentStep}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{ duration: 0.3 }}
          className="min-h-[400px]"
        >
          <h2 className="text-xl font-bold mb-6">{currentStepData.title}</h2>
          
          {currentStep === 5 ? (
            <div className="bg-white rounded-lg p-6 shadow-lg">
              <h3 className="text-xl font-bold mb-6">Récapitulatif de vos informations</h3>
              
              <div className="space-y-6">
                {/* Section Informations de base */}
                <div className="border-b pb-4">
                  <h4 className="font-semibold text-lg mb-3 text-blue-600">Informations préalables</h4>
                  <div className="grid grid-cols-1 gap-3">
                    <div>
                      <span className="font-medium">Email :</span> {formData.email}
                    </div>
                    <div>
                      <span className="font-medium">Forme juridique :</span> {formData["Votre forme juridique"]}
                    </div>
                    <div>
                      <span className="font-medium">Nombre d'associés :</span> {formData["Nombre d'associé"] || "Aucun"}
                    </div>
                  </div>
                  <button 
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentStep(1);
                    }}
                    className="mt-2 text-sm text-blue-500 hover:text-blue-700"
                  >
                    Modifier ces informations
                  </button>
                </div>

                {/* Section Informations de la société */}
                <div className="border-b pb-4">
                  <h4 className="font-semibold text-lg mb-3 text-blue-600">Dénomination et activité</h4>
                  <div className="grid grid-cols-1 gap-3">
                    <div>
                      <span className="font-medium">Objet social :</span> {formData["Votre objet social complet"]}
                    </div>
                    <div>
                      <span className="font-medium">Dénomination sociale :</span> {formData["Votre dénomination sociale"]}
                    </div>
                    <div>
                      <span className="font-medium">Date de début d'activité :</span> {formData["Date de début d'activité"]}
                    </div>
                    <div>
                      <span className="font-medium">Date de clôture :</span> {formData["Date de clôture de l'exercice comptable"]}
                    </div>
                  </div>
                  <button 
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentStep(2);
                    }}
                    className="mt-2 text-sm text-blue-500 hover:text-blue-700"
                  >
                    Modifier ces informations
                  </button>
                </div>

                {/* Section Adresse et capital */}
                <div className="border-b pb-4">
                  <h4 className="font-semibold text-lg mb-3 text-blue-600">Informations juridiques</h4>
                  <div className="grid grid-cols-1 gap-3">
                    <div>
                      <span className="font-medium">Adresse :</span> {formData["ADRESSE SIEGE SOCIAL N° ET RUE"]}
                    </div>
                    <div>
                      <span className="font-medium">Code postal :</span> {formData["CODE POSTALE SIEGE SOCIAL"]}
                    </div>
                    <div>
                      <span className="font-medium">Ville :</span> {formData["VILLE SIEGE SOCIAL"]}
                    </div>
                    <div>
                      <span className="font-medium">Capital :</span> {formData["MONTANT DE CAPITAL"]} €
                    </div>
                    <div>
                      <span className="font-medium">Nombre de parts :</span> {formData["Nombre de parts sociales ou actions"]}
                    </div>
                    <div>
                      <span className="font-medium">Banque :</span> {formData["Nom de LA BANQUE ou est / ou sera déposé le capital"]}
                    </div>
                  </div>
                  <button 
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentStep(3);
                    }}
                    className="mt-2 text-sm text-blue-500 hover:text-blue-700"
                  >
                    Modifier ces informations
                  </button>
                </div>

                {/* Section Associés - Afficher uniquement s'il y a des associés */}
                {formData["Nombre d'associé"] && formData["Nombre d'associé"] !== "" && (
                  <div>
                    <h4 className="font-semibold text-lg mb-3 text-blue-600">Informations des associés</h4>
                    {Array.from({ length: Number(formData["Nombre d'associé"]) }).map((_, index) => (
                      <div key={index} className="mb-4 border-b pb-4">
                        <h5 className="font-medium mb-2">Associé {index + 1}</h5>
                        <div className="grid grid-cols-1 gap-2">
                          <div>
                            <span className="font-bold">Civilité :</span> {formData[`Indiquez la civilité de l'associé ${index + 1}`]}
                          </div>
                          <div>
                            <span className="font-bold">Nom de naissance :</span> {formData[`Indiquez le nom de naissance de l'associé ${index + 1}`]}
                          </div>
                          <div>
                            <span className="font-bold">Nom d'usage :</span> {formData[`Indiquez le nom d'usage de l'associé ${index + 1}`]}
                          </div>
                          <div>
                            <span className="font-bold">Prénom :</span> {formData[`Indiquez le prénom de l'associé ${index + 1}`]}
                          </div>
                          <div>
                            <span className="font-bold">Deuxième prénom :</span> {formData[`Indiquez le deuxième prénom de l'associé ${index + 1}`]}
                          </div>
                          <div>
                            <span className="font-bold">Date de naissance :</span> {formData[`Indiquez la date de naissance de l'associé ${index + 1}`]}
                          </div>
                          <div>
                            <span className="font-bold">Lieu de naissance :</span> {formData[`Indiquez le lieu de naissance de l'associé ${index + 1}`]}
                          </div>
                          <div>
                            <span className="font-bold">Département de naissance :</span> {formData[`Indiquez le département de naissance de l'associé ${index + 1}`]}
                          </div>
                          <div>
                            <span className="font-bold">Nationalité :</span> {formData[`Indiquez la nationalité de l'associé ${index + 1}`]}
                          </div>
                          <div>
                            <span className="font-bold">Adresse :</span> {formData[`Indiquez l'adresse postale de l'associé ${index + 1} (N° de rue nom de rue)`]}
                          </div>
                          <div>
                            <span className="font-bold">Ville :</span> {formData[`Indiquez l'adresse postale de l'associé ${index + 1} (VILLE)`]}
                          </div>
                          <div>
                            <span className="font-bold">Code postal :</span> {formData[`Indiquez l'adresse postale de l'associé ${index + 1} (CODE POSTAL)`]}
                          </div>
                          <div>
                            <span className="font-bold">Statut matrimonial :</span> {formData[`Indiquez le statut matrimonial de l'associé ${index + 1}`]}
                          </div>
                          <div>
                            <span className="font-bold">Pourcentage de détention :</span> {formData[`Indiquez le pourcentage de détention de l'associé ${index + 1}`]}%
                          </div>
                          <div>
                            <span className="font-bold">Est gérant :</span> {formData[`Indiquez si l'associé ${index + 1} est également gérant`]}
                          </div>
                        </div>
                        <button 
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentStep(4);
                            setCurrentAssociate(index + 1);
                          }}
                          className="mt-2 text-sm text-blue-500 hover:text-blue-700"
                        >
                          Modifier ces informations
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              {currentStep === 1 && (
                <>
                  <div className="input-container">
                    <label htmlFor="email" className="input-label font-bold text-gray-600">
                      Adresse mail<span className="asterisk">*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="input_contact"
                      placeholder="Adresse mail"
                      onChange={handleChange}
                      value={formData["email"]}
                      required
                    />
                  </div>

                  <div className="input-container mt-4">
                    <label htmlFor="Votre forme juridique" className="input-label font-bold text-gray-600">
                      Votre forme juridique<span className="asterisk">*</span>
                    </label>
                    <select
                      id="Votre forme juridique"
                      name="Votre forme juridique"
                      className="input_contact"
                      onChange={handleChange}
                      value={formData["Votre forme juridique"]}
                      required
                    >
                      <option value="">Sélectionnez une option</option>
                      <option value="SARL">SARL</option>
                      <option value="EURL">EURL</option>
                      <option value="SAS">SAS</option>
                      <option value="SASU">SASU</option>
                    </select>
                  </div>

                  <div className="input-container mt-4">
                    <label htmlFor="Nombre d'associé" className="input-label font-bold text-gray-600">
                      Nombre d'associé<span className="asterisk">*</span>
                    </label>
                    <select
                      id="Nombre d'associé"
                      name="Nombre d'associé"
                      className="input_contact"
                      onChange={handleChange}
                      value={formData["Nombre d'associé"]}
                    >
                      <option value="">Aucun associé</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                  </div>
                </>
              )}

              {currentStep === 2 && (
                <>
                  <div className="input-container">
                    <label htmlFor="Votre objet social complet" className="input-label font-bold text-gray-600">
                      Objet social complet<span className="asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      id="Votre objet social complet"
                      name="Votre objet social complet"
                      className="input_contact"
                      placeholder="Objet social complet"
                      onChange={handleChange}
                      value={formData["Votre objet social complet"]}
                      required
                    />
                  </div>

                  <div className="input-container mt-4">
                    <label htmlFor="Votre dénomination sociale" className="input-label font-bold text-gray-600">
                      Dénomination sociale<span className="asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      id="Votre dénomination sociale"
                      name="Votre dénomination sociale"
                      className="input_contact"
                      placeholder="Dénomination sociale"
                      onChange={handleChange}
                      value={formData["Votre dénomination sociale"]}
                      required
                    />
                  </div>

                  <div className="input-container mt-4">
                    <label htmlFor="Date de début d'activité" className="input-label font-bold text-gray-600">
                      Date de début d'activité<span className="asterisk">*</span>
                    </label>
                    <input
                      type="date"
                      id="Date de début d'activité"
                      name="Date de début d'activité"
                      className="input_contact"
                      onChange={handleChange}
                      value={formData["Date de début d'activité"]}
                      required
                    />
                  </div>

                  <div className="input-container mt-4">
                    <label htmlFor="Date de clôture de l'exercice comptable" className="input-label font-bold text-gray-600">
                      Date de clôture de l'exercice comptable (JJ/MM)<span className="asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      id="Date de clôture de l'exercice comptable"
                      name="Date de clôture de l'exercice comptable"
                      className="input_contact"
                      onChange={handleDateInput}
                      onKeyDown={handleKeyDown}
                      value={formData["Date de clôture de l'exercice comptable"]}
                      placeholder="JJ/MM"
                      maxLength={5}
                      required
                    />
                  </div>
                </>
              )}

              {currentStep === 3 && (
                <>
                  <div className="input-container">
                    <label htmlFor="ADRESSE SIEGE SOCIAL N° ET RUE" className="input-label font-bold text-gray-600">
                      Adresse siège social n° et rue<span className="asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      id="ADRESSE SIEGE SOCIAL N° ET RUE"
                      name="ADRESSE SIEGE SOCIAL N° ET RUE"
                      className="input_contact"
                      placeholder="Adresse siège social n° et rue"
                      onChange={handleChange}
                      value={formData["ADRESSE SIEGE SOCIAL N° ET RUE"]}
                      required
                    />
                  </div>

                  <div className="input-container mt-4">
                    <label htmlFor="CODE POSTALE SIEGE SOCIAL" className="input-label font-bold text-gray-600">
                      Code postal siège social<span className="asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      id="CODE POSTALE SIEGE SOCIAL"
                      name="CODE POSTALE SIEGE SOCIAL"
                      className="input_contact"
                      placeholder="Code postal siège social"
                      onChange={handleChange}
                      value={formData["CODE POSTALE SIEGE SOCIAL"]}
                      required
                    />
                  </div>

                  <div className="input-container mt-4">
                    <label htmlFor="VILLE SIEGE SOCIAL" className="input-label font-bold text-gray-600">
                      Ville siège social<span className="asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      id="VILLE SIEGE SOCIAL"
                      name="VILLE SIEGE SOCIAL"
                      className="input_contact"
                      placeholder="Ville siège social"
                      onChange={handleChange}
                      value={formData["VILLE SIEGE SOCIAL"]}
                      required
                    />
                  </div>

                  <div className="input-container mt-4">
                    <label htmlFor="MONTANT DE CAPITAL" className="input-label font-bold text-gray-600">
                      Montant de capital<span className="asterisk">*</span>
                    </label>
                    <input
                      type="number"
                      id="MONTANT DE CAPITAL"
                      name="MONTANT DE CAPITAL"
                      className="input_contact"
                      placeholder="Montant de capital"
                      onChange={handleChange}
                      value={formData["MONTANT DE CAPITAL"]}
                      required
                    />
                  </div>

                  <div className="input-container mt-4">
                    <label htmlFor="Nombre de parts sociales ou actions" className="input-label font-bold text-gray-600">
                      Nombre de parts sociales ou actions<span className="asterisk">*</span>
                    </label>
                    <input
                      type="number"
                      id="Nombre de parts sociales ou actions"
                      name="Nombre de parts sociales ou actions"
                      className="input_contact"
                      placeholder="Nombre de parts sociales ou actions"
                      onChange={handleChange}
                      value={formData["Nombre de parts sociales ou actions"]}
                      required
                    />
                  </div>

                  <div className="input-container mt-4">
                    <label htmlFor="Nom de LA BANQUE ou est / ou sera déposé le capital" className="input-label font-bold text-gray-600">
                      Nom de la banque où est/sera déposé le capital<span className="asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      id="Nom de LA BANQUE ou est / ou sera déposé le capital"
                      name="Nom de LA BANQUE ou est / ou sera déposé le capital"
                      className="input_contact"
                      placeholder="Nom de la banque"
                      onChange={handleChange}
                      value={formData["Nom de LA BANQUE ou est / ou sera déposé le capital"]}
                      required
                    />
                  </div>

                  <div className="input-container mt-4">
                    <label htmlFor="Date de clôture du premier exercice comptable" className="input-label font-bold text-gray-600">
                      Date de clôture du premier exercice comptable<span className="asterisk">*</span>
                    </label>
                    <input
                      type="date"
                      id="Date de clôture du premier exercice comptable"
                      name="Date de clôture du premier exercice comptable"
                      className="input_contact"
                      onChange={handleChange}
                      value={formData["Date de clôture du premier exercice comptable"]}
                      required
                    />
                  </div>
                </>
              )}

              {currentStep === 4 && (
                <>
                  {formData["Nombre d'associé"] && 
                    renderAssociateFields(currentAssociate - 1)
                  }
                </>
              )}
            </>
          )}
        </motion.div>
      </AnimatePresence>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const isMobile = width < 768;
      setIsMobile(isMobile);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("session_id")) {
      handleSubmitAfterPayment();
      setIsPaymentSuccess(true);
    }
  }, [location.search]);

  const handleSubmitAfterPayment = async () => {
    setLoading(true);
    setErrorMessage("");
    try {
      // Ici on peut simplement mettre à jour l'interface utilisateur
      setIsPaymentSuccess(true);
    } catch (error) {
      console.error("Erreur:", error);
      setErrorMessage("Une erreur est survenue");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleDateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^0-9]/g, "");
    let day = value.slice(0, 2);
    let month = value.slice(2, 4);

    if (day.length === 2 && parseInt(day) > 31) {
      day = "31";
    }

    if (month.length === 2 && parseInt(month) > 12) {
      month = "12";
    }

    if (value.length >= 2) {
      value = day + (month.length > 0 ? "/" + month : "");
    } else {
      value = day;
    }

    setFormData(prevData => ({
      ...prevData,
      "Date de clôture de l'exercice comptable": value
    }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const currentDate = formData["Date de clôture de l'exercice comptable"];

    if (e.key === "Backspace") {
      e.preventDefault();

      if (currentDate.length === 3) {
        setFormData(prevData => ({
          ...prevData,
          "Date de clôture de l'exercice comptable": currentDate.slice(0, 2)
        }));
      } else {
        setFormData(prevData => ({
          ...prevData,
          "Date de clôture de l'exercice comptable": currentDate.slice(0, -1)
        }));
      }
    }
  };

  // Ajoutez d'abord l'état pour gérer l'associé actuel en haut du composant
  const [currentAssociate, setCurrentAssociate] = useState(1);

  // Modifiez la fonction renderAssociateFields
  const renderAssociateFields = (index: number) => {
    const associateNumber = index + 1;
    const totalAssociates = Number(formData["Nombre d'associé"]);
    
    return (
      <AnimatePresence mode="wait">
        <motion.div
          key={associateNumber}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{ duration: 0.3 }}
          className="relative"
        >
          <div className="flex justify-between items-center mb-4 border-b pb-2">
            <h3 className="text-lg text-gray-700">Associé {associateNumber}</h3>
            <div className="text-sm text-gray-500">
              {associateNumber} sur {totalAssociates}
            </div>
          </div>

          <div className="space-y-4">
            <div className="input-container">
              <label htmlFor={`Indiquez la civilité de l'associé ${associateNumber}`} className="input-label text-gray-600">
                Civilité<span className="asterisk">*</span>
              </label>
              <select
                id={`Indiquez la civilité de l'associé ${associateNumber}`}
                name={`Indiquez la civilité de l'associé ${associateNumber}`}
                className="input_contact"
                onChange={handleChange}
                value={formData[`Indiquez la civilité de l'associé ${associateNumber}`]}
                required
              >
                <option value="">Sélectionner la civilité</option>
                <option value="Madame">MADAME</option>
                <option value="Monsieur">MONSIEUR</option>
              </select>
            </div>

            <div className="input-container">
              <label htmlFor={`Indiquez le nom de naissance de l'associé ${associateNumber}`} className="input-label text-gray-600">
                Nom de naissance<span className="asterisk">*</span>
              </label>
              <input
                type="text"
                id={`Indiquez le nom de naissance de l'associé ${associateNumber}`}
                name={`Indiquez le nom de naissance de l'associé ${associateNumber}`}
                className="input_contact"
                placeholder="Nom de naissance"
                onChange={handleChange}
                value={formData[`Indiquez le nom de naissance de l'associé ${associateNumber}`]}
                required
              />
            </div>

            <div className="input-container">
              <label htmlFor={`Indiquez le nom d'usage de l'associé ${associateNumber}`} className="input-label text-gray-600">
                Nom d'usage<span className="asterisk">*</span>
              </label>
              <input
                type="text"
                id={`Indiquez le nom d'usage de l'associé ${associateNumber}`}
                name={`Indiquez le nom d'usage de l'associé ${associateNumber}`}
                className="input_contact"
                placeholder="Nom d'usage"
                onChange={handleChange}
                value={formData[`Indiquez le nom d'usage de l'associé ${associateNumber}`]}
                required
              />
            </div>

            <div className="input-container">
              <label htmlFor={`Indiquez le prénom de l'associé ${associateNumber}`} className="input-label text-gray-600">
                Prénom<span className="asterisk">*</span>
              </label>
              <input
                type="text"
                id={`Indiquez le prénom de l'associé ${associateNumber}`}
                name={`Indiquez le prénom de l'associé ${associateNumber}`}
                className="input_contact"
                placeholder="Prénom"
                onChange={handleChange}
                value={formData[`Indiquez le prénom de l'associé ${associateNumber}`]}
                required
              />
            </div>

            <div className="input-container">
              <label htmlFor={`Indiquez le deuxième prénom de l'associé ${associateNumber}`} className="input-label text-gray-600">
                Deuxième prénom<span className="asterisk">*</span>
              </label>
              <input
                type="text"
                id={`Indiquez le deuxième prénom de l'associé ${associateNumber}`}
                name={`Indiquez le deuxième prénom de l'associé ${associateNumber}`}
                className="input_contact"
                placeholder="Deuxième prénom"
                onChange={handleChange}
                value={formData[`Indiquez le deuxième prénom de l'associé ${associateNumber}`]}
                required
              />
            </div>

            <div className="input-container">
              <label htmlFor={`Indiquez la date de naissance de l'associé ${associateNumber}`} className="input-label text-gray-600">
                Date de naissance<span className="asterisk">*</span>
              </label>
              <input
                type="date"
                id={`Indiquez la date de naissance de l'associé ${associateNumber}`}
                name={`Indiquez la date de naissance de l'associé ${associateNumber}`}
                className="input_contact"
                onChange={handleChange}
                value={formData[`Indiquez la date de naissance de l'associé ${associateNumber}`]}
                required
              />
            </div>

            <div className="input-container">
              <label htmlFor={`Indiquez le lieu de naissance de l'associé ${associateNumber}`} className="input-label text-gray-600">
                Lieu de naissance<span className="asterisk">*</span>
              </label>
              <input
                type="text"
                id={`Indiquez le lieu de naissance de l'associé ${associateNumber}`}
                name={`Indiquez le lieu de naissance de l'associé ${associateNumber}`}
                className="input_contact"
                placeholder="Lieu de naissance"
                onChange={handleChange}
                value={formData[`Indiquez le lieu de naissance de l'associé ${associateNumber}`]}
                required
              />
            </div>

            <div className="input-container">
              <label htmlFor={`Indiquez le département de naissance de l'associé ${associateNumber}`} className="input-label text-gray-600">
                Département de naissance<span className="asterisk">*</span>
              </label>
              <input
                type="text"
                id={`Indiquez le département de naissance de l'associé ${associateNumber}`}
                name={`Indiquez le département de naissance de l'associé ${associateNumber}`}
                className="input_contact"
                placeholder="Département de naissance"
                onChange={handleChange}
                value={formData[`Indiquez le département de naissance de l'associé ${associateNumber}`]}
                required
              />
            </div>

            <div className="input-container">
              <label htmlFor={`Indiquez la nationalité de l'associé ${associateNumber}`} className="input-label text-gray-600">
                Nationalité<span className="asterisk">*</span>
              </label>
              <input
                type="text"
                id={`Indiquez la nationalité de l'associé ${associateNumber}`}
                name={`Indiquez la nationalité de l'associé ${associateNumber}`}
                className="input_contact"
                placeholder="Nationalité"
                onChange={handleChange}
                value={formData[`Indiquez la nationalité de l'associé ${associateNumber}`]}
                required
              />
            </div>

            <div className="input-container">
              <label htmlFor={`Indiquez l'adresse postale de l'associé ${associateNumber} (N° de rue nom de rue)`} className="input-label text-gray-600">
                Adresse<span className="asterisk">*</span>
              </label>
              <input
                type="text"
                id={`Indiquez l'adresse postale de l'associé ${associateNumber} (N° de rue nom de rue)`}
                name={`Indiquez l'adresse postale de l'associé ${associateNumber} (N° de rue nom de rue)`}
                className="input_contact"
                placeholder="Numéro et nom de rue"
                onChange={handleChange}
                value={formData[`Indiquez l'adresse postale de l'associé ${associateNumber} (N° de rue nom de rue)`]}
                required
              />
            </div>

            <div className="input-container">
              <label htmlFor={`Indiquez l'adresse postale de l'associé ${associateNumber} (VILLE)`} className="input-label text-gray-600">
                Ville<span className="asterisk">*</span>
              </label>
              <input
                type="text"
                id={`Indiquez l'adresse postale de l'associé ${associateNumber} (VILLE)`}
                name={`Indiquez l'adresse postale de l'associé ${associateNumber} (VILLE)`}
                className="input_contact"
                placeholder="Ville"
                onChange={handleChange}
                value={formData[`Indiquez l'adresse postale de l'associé ${associateNumber} (VILLE)`]}
                required
              />
            </div>

            <div className="input-container">
              <label htmlFor={`Indiquez l'adresse postale de l'associé ${associateNumber} (CODE POSTAL)`} className="input-label text-gray-600">
                Code postal<span className="asterisk">*</span>
              </label>
              <input
                type="text"
                id={`Indiquez l'adresse postale de l'associé ${associateNumber} (CODE POSTAL)`}
                name={`Indiquez l'adresse postale de l'associé ${associateNumber} (CODE POSTAL)`}
                className="input_contact"
                placeholder="Code postal"
                onChange={handleChange}
                value={formData[`Indiquez l'adresse postale de l'associé ${associateNumber} (CODE POSTAL)`]}
                required
              />
            </div>

            <div className="input-container">
              <label htmlFor={`Indiquez le statut matrimonial de l'associé ${associateNumber}`} className="input-label text-gray-600">
                Statut matrimonial<span className="asterisk">*</span>
              </label>
              <select
                id={`Indiquez le statut matrimonial de l'associé ${associateNumber}`}
                name={`Indiquez le statut matrimonial de l'associé ${associateNumber}`}
                className="input_contact"
                onChange={handleChange}
                value={formData[`Indiquez le statut matrimonial de l'associé ${associateNumber}`]}
                required
              >
                <option value="">Sélectionnez un statut</option>
                <option value="Célibataire">Célibataire</option>
                <option value="Marié">Marié</option>
                <option value="Divorcé">Divorcé</option>
              </select>
            </div>

            <div className="input-container">
              <label htmlFor={`Indiquez le pourcentage de détention de l'associé ${associateNumber}`} className="input-label text-gray-600">
                Pourcentage de détention<span className="asterisk">*</span>
              </label>
              <input
                type="text"
                id={`Indiquez le pourcentage de détention de l'associé ${associateNumber}`}
                name={`Indiquez le pourcentage de détention de l'associé ${associateNumber}`}
                className="input_contact"
                placeholder="Pourcentage de détention"
                onChange={handleChange}
                value={formData[`Indiquez le pourcentage de détention de l'associé ${associateNumber}`]}
                required
              />
            </div>

            <div className="input-container">
              <label htmlFor={`Indiquez si l'associé ${associateNumber} est également gérant`} className="input-label text-gray-600">
                Est gérant ?<span className="asterisk">*</span>
              </label>
              <select
                id={`Indiquez si l'associé ${associateNumber} est également gérant`}
                name={`Indiquez si l'associé ${associateNumber} est également gérant`}
                className="input_contact"
                onChange={handleChange}
                value={formData[`Indiquez si l'associé ${associateNumber} est également gérant`]}
                required
              >
                <option value="">Sélectionnez une option</option>
                <option value="Oui">Oui</option>
                <option value="Non">Non</option>
              </select>
            </div>
          </div>

          {/* Navigation avec flèches */}
          {totalAssociates > 1 && (
            <div className="flex items-center justify-center space-x-4 mt-6">
              <button
                onClick={() => associateNumber > 1 && handleAssociateChange(associateNumber - 1)}
                className={`w-8 h-8 rounded-full flex items-center justify-center transition-all duration-200 
                  ${associateNumber > 1 
                    ? 'text-gray-400 hover:text-blue-600' 
                    : 'text-gray-200 cursor-not-allowed'}`}
                disabled={associateNumber <= 1}
              >
                <IoChevronBack className="w-5 h-5" />
              </button>

              <div className="flex space-x-2">
                {Array.from({ length: totalAssociates }).map((_, idx) => (
                  <button
                    key={idx}
                    onClick={() => handleAssociateChange(idx + 1)}
                    className={`w-2 h-2 rounded-full transition-all duration-200 ${
                      idx + 1 === associateNumber 
                        ? 'bg-blue-600 w-4' 
                        : 'bg-gray-300 hover:bg-gray-400'
                    }`}
                  />
                ))}
              </div>

              <button
                onClick={() => associateNumber < totalAssociates && handleAssociateChange(associateNumber + 1)}
                className={`w-8 h-8 rounded-full flex items-center justify-center transition-all duration-200 
                  ${associateNumber < totalAssociates 
                    ? 'text-gray-400 hover:text-blue-600' 
                    : 'text-gray-200 cursor-not-allowed'}`}
                disabled={associateNumber >= totalAssociates}
              >
                <IoChevronForward className="w-5 h-5" />
              </button>
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    );
  };

  const handlePayment = async () => {
    const stripe = await stripePromise;
    if (!stripe) {
      setErrorMessage("Stripe n'a pas pu être initialisé");
      return;
    }
    
    try {
      const response = await axios({
        method: 'post',
        url: 'https://fbconseil-c706cd2b1837.herokuapp.com/stripe-status/create-checkout-session-status',
        data: formData,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        withCredentials: false
      });

      const { id: sessionId } = response.data;
      const result = await stripe.redirectToCheckout({ sessionId });

      if (result.error) {
        setErrorMessage(result.error.message || "Une erreur est survenue lors du paiement");
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage("Erreur lors du paiement: " + error.message);
      } else {
        setErrorMessage("Une erreur inconnue est survenue lors du paiement");
      }
    }
  };

  // Ajoutez une fonction pour gérer le changement d'associé
  const handleAssociateChange = (newAssociateNumber: number) => {
    if (isStepComplete(4)) {
      setCurrentAssociate(newAssociateNumber);
    } else {
      const missingFields = getMissingFields(4);
      setErrorMessage(`Veuillez remplir les champs suivants pour l'associé ${currentAssociate} : ${missingFields.join(', ')}`);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  // Ajoutez cette fonction avant handleOpenModal
  const isFormValid = () => {
    const requiredFields = [
      "email",
      "Votre forme juridique",
      "CODE POSTALE SIEGE SOCIAL",
      "Votre objet social complet",
      "Votre dénomination sociale",
      "Date de début d'activité",
      "Date de clôture de l'exercice comptable",
      "ADRESSE SIEGE SOCIAL N° ET RUE",
      "VILLE SIEGE SOCIAL",
      "MONTANT DE CAPITAL",
      "Nombre de parts sociales ou actions",
      "Nom de LA BANQUE ou est / ou sera déposé le capital",
      "Date de clôture du premier exercice comptable",
    ];

    // Vérifier les champs de base
    for (const field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }

    // Si des associés sont sélectionnés, vérifier leurs champs
    const numberOfAssociates = parseInt(formData["Nombre d'associé"]);
    if (numberOfAssociates > 0) {
      for (let i = 1; i <= numberOfAssociates; i++) {
        const associateFields = [
          `Indiquez la civilité de l'associé ${i}`,
          `Indiquez le nom de naissance de l'associé ${i}`,
          `Indiquez le nom d'usage de l'associé ${i}`,
          `Indiquez le prénom de l'associé ${i}`,
          `Indiquez le deuxième prénom de l'associé ${i}`,
          `Indiquez la date de naissance de l'associé ${i}`,
          `Indiquez le lieu de naissance de l'associé ${i}`,
          `Indiquez le département de naissance de l'associé ${i}`,
          `Indiquez la nationalité de l'associé ${i}`,
          `Indiquez l'adresse postale de l'associé ${i} (N° de rue nom de rue)`,
          `Indiquez l'adresse postale de l'associé ${i} (VILLE)`,
          `Indiquez l'adresse postale de l'associé ${i} (CODE POSTAL)`,
          `Indiquez le statut matrimonial de l'associé ${i}`,
          `Indiquez le pourcentage de détention de l'associé ${i}`,
          `Indiquez si l'associé ${i} est également gérant`
        ];

        for (const field of associateFields) {
          if (!formData[field]) {
            return false;
          }
        }
      }
    }

    return true;
  };

  // Ajoutez une nouvelle fonction pour générer les statuts
  const generateStatus = async () => {
    try {
      const dataToSend = {
        ...formData,
        name: formData["Votre dénomination sociale"]
      };

      const statusResponse = await axios({
        method: 'post',
        url: 'https://fbconseil-c706cd2b1837.herokuapp.com/api/getStatus',
        data: dataToSend,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        withCredentials: false,
        timeout: 30000,
        validateStatus: function (status) {
          return status >= 200 && status < 500;
        }
      });

      if (!statusResponse.data.success) {
        setErrorMessage("Erreur lors de la génération des statuts: " + statusResponse.data.message);
        return false;
      }

      return true;
    } catch (error: unknown) {  // Typer l'erreur comme unknown
      // Vérifier si c'est une erreur Axios avec type guard
      if (axios.isAxiosError(error)) {
        if (error.message === "Network Error" && error.response === undefined) {
          return true;
        }
        
        if (error.response) {
          console.error("Données d'erreur:", error.response.data);
          console.error("Status:", error.response.status);
          setErrorMessage(`Erreur ${error.response.status}: ${error.response.data.message || 'Une erreur est survenue'}`);
        } else if (error.request) {
          console.error("Pas de réponse reçue");
          setErrorMessage("Le serveur ne répond pas. Veuillez vérifier votre connexion.");
        } else {
          console.error("Erreur de configuration:", error.message);
          setErrorMessage("Erreur de configuration de la requête");
        }
      } else {
        // Pour les autres types d'erreurs
        console.error("Erreur non-Axios:", error);
        setErrorMessage("Une erreur inconnue est survenue");
      }
      return false;
    }
  };

  // Modifiez la fonction handleOpenModal
  const handleOpenModal = async () => {
    if (isFormValid()) {
      setIsGenerating(true); // Activer le loader
      // Générer d'abord les statuts
      const success = await generateStatus();
      setIsGenerating(false); // Désactiver le loader
      if (success) {
        setIsModalOpen(true);
        setErrorMessage("");
      }
    } else {  
      setErrorMessage("Veuillez remplir tous les champs requis.");
    }
  };

  // Ajoutez cet état pour le loader
  const [isGenerating, setIsGenerating] = useState(false);

  return (
    <>
      <Arrow />
      <div
        className="min-h-screen flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8 pb-20"
        style={{ backgroundColor: "var(--background-color)" }}
      >
        <div className="text-left px-4 sm:px-6 lg:px-8 mb-10 mt-12">
          <h1 className="text-3xl mt-20 font-bold text-black">
            Vous souhaitez obtenir VOS STATUTS ?
          </h1>
          <p className="font-semibold mt-6">
            Afin de vous accompagner jusqu'au bout dans le lancement de votre
            projet, vous pouvez également obtenir les statuts de votre société
            au tarif de 50€ HT seulement !
          </p>
          <p className="font-semibold mt-6">
            Rien de plus simple, complétez simplement le formulaire:
          </p>
        </div>

        {isMobile && (
          <motion.div
            className="progress-bar"
            style={{
              scaleX,
              originX: 0,
              scaleY: 2,
              backgroundColor: "#3f9bf1",
            }}
          />
        )}
        <form
          className="w-full max-w-3xl space-y-6 overflow-visible px-6 sm:px-0"
        >
          {renderStepIndicator()}
          {renderCurrentStep()}
          
          <div className="flex justify-between mt-8 mb-10">
            {currentStep > 1 && (
              <ShimmerButton
                type="button"
                className="bg-gray-500 text-white px-6 py-2 rounded-lg"
                onClick={previousStep}
              >
                Précédent
              </ShimmerButton>
            )}
            
            {currentStep < steps.length ? (
              <ShimmerButton
                type="button"
                className="bg-blue-600 text-white px-6 py-2 rounded-lg ml-auto"
                onClick={() => {
                  const missingFields = getMissingFields(currentStep);
                  if (missingFields.length > 0) {
                    setErrorMessage(`Veuillez remplir les champs suivants : ${missingFields.join(', ')}`);
                    setTimeout(() => {
                      setErrorMessage("");
                    }, 5000);
                  } else {
                    nextStep();
                  }
                }}
              >
                Suivant
              </ShimmerButton>
            ) : (
              <ShimmerButton
                type="button"
                className="bg-purple-500 text-white px-4 sm:px-6 py-2 rounded-lg ml-auto relative min-w-[160px] sm:min-w-[200px]"
                onClick={() => {
                  const missingFields = getMissingFields(currentStep);
                  if (missingFields.length > 0) {
                    setErrorMessage(`Veuillez remplir les champs suivants : ${missingFields.join(', ')}`);
                    setTimeout(() => {
                      setErrorMessage("");
                    }, 5000);
                  } else {
                    handleOpenModal();
                  }
                }}
                disabled={isGenerating}
              >
                {isGenerating ? (
                  <div className="flex items-center justify-center space-x-2">
                    <div className="w-4 h-4 sm:w-5 sm:h-5 border-2 border-white rounded-full animate-spin border-t-transparent"></div>
                    <span className="text-xs sm:text-sm">Génération...</span>
                  </div>
                ) : (
                  <span className="text-sm sm:text-base">Générer mes statuts</span>
                )}
              </ShimmerButton>
            )}
          </div>
        </form>

        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50 p-2 sm:p-4">
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md"></div>
            <div className="bg-white border border-black rounded-lg relative flex flex-col lg:flex-row w-full max-w-2xl z-10 max-h-[95vh] overflow-hidden">
              {/* Bouton fermer */}
              <button
                className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 z-20 bg-white rounded-full w-6 h-6 sm:w-8 sm:h-8 flex items-center justify-center text-xl"
                onClick={() => setIsModalOpen(false)}
              >
                &times;
              </button>

              {/* Contenu gauche */}
              <div className="lg:w-1/2 w-full p-4 sm:p-6 flex flex-col justify-center">
                <h2 className="text-lg sm:text-xl lg:text-2xl font-bold mb-2 sm:mb-4">
                  Vos statuts ont été générés avec succès !
                </h2>
                <div className="text-gray-600 space-y-2 sm:space-y-4">
                  <p className="text-xs sm:text-sm lg:text-base">
                    Vous allez recevoir vos statuts au format PDF dans votre boîte mail dans quelques instants.
                  </p>
                  <p className="text-xs sm:text-sm lg:text-base">
                    N'oubliez pas de vérifier vos spams si vous ne voyez pas l'email.
                  </p>
                </div>
              </div>

              {/* Contenu droit - Aperçu */}
              <div className="lg:w-1/2 w-full border-t lg:border-t-0 lg:border-l border-gray-200">
                <div 
                  className="overflow-y-auto p-2 sm:p-4"
                  style={{ 
                    maxHeight: "50vh",
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#CBD5E0 #EDF2F7'
                  }}
                >
                  {imagePaths.map((path, index) => (
                    <img
                      key={index}
                      src={path}
                      alt={`Page ${index + 1}`}
                      className="w-full h-auto mb-2 sm:mb-4 rounded-lg shadow-sm"
                      loading="lazy"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {isPaymentSuccess && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md"></div>
            <div className="bg-white border border-black rounded-lg p-4 relative max-w-2xl w-full z-10">
              <button
                className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                onClick={() => {
                  setIsPaymentSuccess(false);
                  navigate("/"); // Redirigez vers la page d'accueil après avoir fermé la modal
                }}
              >
                &times;
              </button>
              <div className="flex flex-col items-center">
                <h2 className="text-lg font-bold mb-2">
                  Merci pour votre confiance !
                </h2>
                <p>
                  Vous allez recevoir un mail (pdf) avec vos statuts,
                  n'oubliez pas de vérifier vos spams.
                </p>
              </div>
            </div>
          </div>
        )}

        {errorMessage && (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              className={`fixed ${
                isMobile 
                  ? 'bottom-4 left-4 right-4' 
                  : 'top-4 left-1/2 transform -translate-x-1/2'
              } bg-white border-l-4 border-red-500 rounded-lg shadow-lg p-4 z-50 ${
                isMobile ? 'w-auto' : 'max-w-md w-full mx-4'
              }`}
            >
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-red-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm leading-5 text-gray-500">
                    {errorMessage}
                  </p>
                </div>
                <button
                  className="ml-2 text-gray-400"
                  onClick={() => setErrorMessage("")}
                >
                  <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </motion.div>
          </AnimatePresence>
        )}
      </div>
    </>
  );
};

export default Status;